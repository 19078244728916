import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import Mustache from "mustache";
import { useErrorBoundary } from "react-error-boundary";
import { getBatchPrintZipCodes, getBatchPrintHCPs, getBatchPrintPrescriber } from "../API/batchPrint";
import { getConfigValue, getConfigCollection } from "../util/getConfigValue";
import { AppContext } from "../util/AppContext";
import { ProductsModal, ProductsModalButton } from "../components/ProductsModal";
import MessageDetailsModal from "../components/MessageDetailsModal";
import PrintOptionDropdown from "../components/printOptions/printOptionDropdown";
import CategoryDropdown from "../components/CategoryDropdown";
import BatchPrintList from "../components/BatchPrintList";
import ToastAlert from "../components/ToastAlert";
import { filterByValue } from "../util/filterByValue";
import { batchPrintDocumentGenerate, documentGenerationType } from "../API/documentGenerate";
import PreviewModal from '../components/PreviewModal';
import TargetModal from "../components/modals/TargetModal";

function SelectTargetBatchPage() {
    const [appState, setAppState] = useContext(AppContext);
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();

    const [productImageSource, setProductImageSource] = useState(appState?.currentProduct?.imagePath);
    const [currentProduct, setCurrentProduct] = useState(appState?.currentProduct);
    const [pageCategory, setPageCategory] = useState(appState.category);
    const [messageCode, setMessageCode] = useState(appState.category?.messageCode);
    const [batchPrintCategories, setBatchPrintCategories] = useState(appState?.batchPrintCategories);

    const [searchInput, setSearchInput] = useState("");
    const [selectedColumnState, setSelectedColumnState] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);
    const [hcpsBatchResponses, setHcpsBatchResponses] = useState();
    const [selectedZipCodes, setSelectedZipCodes] = useState([]);
    const [filteredBatchRows, setFilteredBatchRows] = useState([]);
    const [prescriberData, setPrescriberData] = useState([]);
    const [maxDisplayColumns, setMaxDisplayColumns] = useState(5);
    const [exceedMaxColumns, setExceedMaxColumns] = useState(false);

    const minPrintColumns = getConfigValue(appState.config.PlanList.MinPrintableColumns, appState)?.Value || 3;
    const maxSelectedBatch = getConfigValue(appState.config.BatchPrint?.Page?.MaxNumber?.Print, appState)?.Value || 10;
    const [selectedBatch, setSelectedBatch] = useState([]);

    const [noResultsFound, setNoResultsFound] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [showMessageDetailsModal, setShowMessageDetailsModal] = useState(false);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [isConfigMenuVisible, setIsConfigMenuVisible] = useState(false);
    const [configTargetNameisChecked, setConfigTargetNameisChecked] = useState(false);
    const [configEHRisChecked, setConfigEHRisChecked] = useState(false);
    const [configCoverisChecked, setConfigCoverisChecked] = useState(false);

    const configMenuRef = useRef(null);
    const configMenuContentRef = useRef(null);

    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [toastAutohide, setToastAutohide] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const groupPracticeEnabled = getConfigValue(appState.config.GroupPractice?.Enabled, appState)?.Value === "true";
    const printOptionsEhrBackPageEnabled = getConfigValue(appState.config.PrintOptions?.EhrBackPage?.Enabled, appState)?.Value === "true";
    const printOptionsCoverPageEnabled = getConfigValue(appState.config?.PrintOptions?.BatchPrint?.CoverPage?.Enabled, appState)?.Value === "true";
    const batchPrintConfig = useMemo(() => getConfigCollection(appState.config?.BatchPrint, appState, 'BatchPrint'), [appState]);
    const [savingsCardIsChecked, setSavingsCardIsChecked] = useState(getConfigValue(appState.config?.PrintOptions?.SavingsCard?.Default, appState)?.Value === "true");
    const savingsCardIsEnabled = getConfigValue(appState.config?.PrintOptions?.SavingsCard?.Enabled, appState)?.Value === "true";
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [pdfFileName, setPdfFileName] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');

    const [hcpCheckbox, setHcpCheckbox] = useState(true);
    const [groupPracticeCheckbox, setGroupPracticeCheckbox] = useState(true);

    const [showTargetModal, setShowTargetModal] = useState(false);

    const isValid = selectedBatch.length >= 1 && selectedBatch.length <= maxSelectedBatch && selectedColumnState.length >= minPrintColumns;

    useEffect(() => {
        if (pageCategory) {
            updateSelectMessageCategory(pageCategory);
        }
    }, [pageCategory]);

    useEffect(() => {
        const getBatchZipCodes = async () => {
            const zipCodeResponse = await getBatchPrintZipCodes(appState?.territory?.id, appState?.category?.messageTypeId, appState?.currentProduct?.clientProductId);
            setZipCodes(zipCodeResponse.map(m => ({ value: m.zipCode, label: m.zipCode })));
        }

        getBatchZipCodes().catch(error => { showBoundary(error) });
    }, [appState.territoryId, appState.category.messageTypeId, appState.currentProduct.clientProductId])


    useEffect(() => {
        const getHCPsBatch = async () => {
            const hcpsBatch = await getBatchPrintHCPs(appState?.category?.messageTypeId, appState?.currentProduct?.clientProductId, appState?.territory?.id, selectedZipCodes.map(m => (m.value)));
            setHcpsBatchResponses(hcpsBatch);
            setFilteredBatchRows(hcpsBatch.rows);
        }

        getHCPsBatch().catch(error => { showBoundary(error) });
    }, [appState.territory.id, appState.category.messageTypeId, appState.currentProduct.clientProductId, selectedZipCodes])

    useEffect(() => {
        const getPrescriberBatch = async (rowData) => {
            const prescriberBatch = await getBatchPrintPrescriber(rowData.type, appState.messageCode, rowData.id, appState?.currentProduct?.clientProductId, appState?.category?.messageTypeId, appState?.territory?.id)
            setPrescriberData(prescriberBatch.dynamicTable);
            if (prescriberBatch.dynamicTable) {
                //pre select the columns to the max number of visible columns defined
                const visibleColumns = [];
                const maxPrintableColumns = getConfigValue(appState.config.PlanList.MaxPrintableColumns, appState)?.Value;
                for (let index = 0; index < maxPrintableColumns && index < prescriberBatch.dynamicTable.headers.length; index++) {
                    visibleColumns.push(prescriberBatch.dynamicTable.headers[index].key);
                }
                setSelectedColumnState(visibleColumns);
                setExceedMaxColumns(prescriberBatch.dynamicTable?.headers.length > maxDisplayColumns);
            }
        }

        let row = [];
        if (hcpsBatchResponses) {
            row = hcpsBatchResponses.rows.map(d => d.data)[0];
            if (row) {
                getPrescriberBatch(row).catch(error => { showBoundary(error) });
            }
        }

    }, [hcpsBatchResponses, appState.territory.id, appState.category.messageTypeId, appState.currentProduct.clientProductId, appState.messageCode])

    useEffect(() => {
        if (appState?.config?.PlanList?.MaxDisplayColumns) {
            setMaxDisplayColumns(parseInt(appState.config.PlanList.MaxDisplayColumns.Value));
        }
    }, [appState]);


    useEffect(() => {
        if (isLoading) {
            setFeedbackMessage(getConfigValue(appState.config.Common?.Loading, appState)?.Value);
        } else if (noResultsFound) {
            setFeedbackMessage(getConfigValue(appState.config.Common?.NoResultsFound, appState)?.Value);
        } else {
            setFeedbackMessage('');
        }
    }, [isLoading, noResultsFound, appState]);

    useEffect(() => {
        if (!hcpsBatchResponses) {
            return;
        }
        let results = hcpsBatchResponses?.rows;
        if (groupPracticeEnabled) {
            results = results.filter(row => (row.data.type === "Prescriber" && hcpCheckbox) || (row.data.type === "Prescriber Group Practice" && groupPracticeCheckbox));
        }
        if (searchInput.length > 0) {
            results = filterByValue(results, searchInput.trim(), [], true)
            setFilteredBatchRows(results)
            if (!results.length) {
                setNoResultsFound(true);
            } else {
                setNoResultsFound(false);
            }
        } else if (hcpsBatchResponses) {
            setFilteredBatchRows(results);
        }

    }, [searchInput, hcpsBatchResponses, hcpCheckbox, groupPracticeCheckbox])

    function handleHcpCheckBoxClick(event) {
        setHcpCheckbox(event.target.checked);
    }

    function handleGroupPracticeCheckBoxClick(event) {
        setGroupPracticeCheckbox(event.target.checked)
    }

    function handleRemoveClick(item, event) {
        const isClicked = event.target;

        if (isLoading) {
            event.preventDefault();
            return;
        }

        if (isClicked) {
            setSelectedBatch((originalBatch) => originalBatch.filter((d) => d.data.id !== item.data.id));

        }
    }

    function handleAddClick(data, event) {
        const isClicked = event.target;
        const maxReached = selectedBatch.length >= maxSelectedBatch;

        if (isLoading) {
            event.preventDefault();
            return;
        }

        if (isClicked && !maxReached) {
            data.includeTargetName = configTargetNameisChecked;
            setSelectedBatch((originalBatch) => [...originalBatch, data]);

        }

        if (isClicked && maxReached) {
            event.preventDefault();
            showMaxTargets();
        }
    }

    function handleZipCode(zipCodes) {
        setSelectedZipCodes(zipCodes);
    }

    function handleChange(event) {
        setSearchInput(event.target.value)
    }

    function triggerToastAlert(title, message, autohide) {
        setToastTitle(title);
        setToastMessage(message);
        setShowToast(true);
        setToastAutohide(autohide);
    }

    function showMaxTargets() {
        const errorMessage = getConfigValue(appState.config?.BatchPrint?.Page?.MaxPrintableTargets?.Body, appState)?.Value;
        triggerToastAlert(
            getConfigValue(appState.config?.BatchPrint?.Page?.MaxPrintableTargets?.Title, appState)?.Value,
            Mustache.render(errorMessage, batchPrintConfig),
            false
        );
    }

    function updateSelectMessageCategory(pageCategory) {
        setMessageCode(pageCategory.messageCode);
        setAppState({
            ...appState,
            category: pageCategory,
            messageCode: pageCategory.messageCode,
        });
    }

    function onDetailsCategoryClick(category) {
        setPageCategory(category);
        setShowMessageDetailsModal(false);
        updateSelectMessageCategory(category);
        setHcpsBatchResponses(null);
        setSelectedBatch([]);
    }

    function onDetailsClick() {
        setShowMessageDetailsModal(true);
    }

    function onProductClick(product) {
        setAppState({ ...appState, currentProduct: product });
        setCurrentProduct(product);
        setShowProductsModal(false);
        setProductImageSource(product.imagePath)
        navigate('/MessageSelectionPage');
    }

    function handleBackButton() {
        navigate('/MessageSelectionPage');
    }

    function closeOpenMenus(event) {
        // click outside of menus and content menus = close menus
        if (configMenuRef.current && !configMenuRef.current.contains(event.target) && !configMenuContentRef.current.contains(event.target) && isConfigMenuVisible) {
            setIsConfigMenuVisible(false);
        }
    }

    function onOptionsClick(item) {
        const toInclude = item.includeTargetName;
        const target = selectedBatch.find((t) => t.data.id === item.data.id);
        if (!toInclude) {
            setConfigTargetNameisChecked(false);
        }
        if (!target && toInclude) {
            if (selectedBatch.length >= maxSelectedBatch) {
                showMaxTargets();
                return false;
            }
            setSelectedBatch((originalPlans) => [...originalPlans, item]);
            return true;
        }
        setSelectedBatch((originalPlans) => [...originalPlans.filter((t) => t.data.id !== target.data.id), item]);
        return true;

    }

    async function loadDoc(generationType) {
        setIsLoading(true);

        const props = {
            productId: appState.currentProduct.clientProductId,
            marketId: appState.market.id,
            territoryId: appState.territory.id,
            addEHRBackPage: configEHRisChecked,
            addCoverPage: configCoverisChecked,
            generateFor: generationType,
            messageType: appState.category.messageCode,
            messageTypeId: appState.category.messageTypeId,
            columnKeys: selectedColumnState,
            improvedAccessPlan: null,
            hcps: selectedBatch.map((item) => {
                return {
                    printHCPName: item.includeTargetName,
                    level: item.data.type,
                    hcpId: item.data.id
                }
            }),
            includeSavingsCard: savingsCardIsChecked
        };

        const response = await batchPrintDocumentGenerate(props)
            .then((responseJson) => {
                setIsLoading(false);

                // store the properties of the last document generated
                setAppState({
                    ...appState,
                    lastGeneratedDocument: props,
                    sellSheetId: responseJson.sellSheetId
                });

                return responseJson;
            })
            .catch(err => {
                setShowPreviewModal(false);
                setIsLoading(false);
                triggerToastAlert("Error", "There was an error generating the document. Please try again.", true);
                console.error(err)
            });
        return response;
    }

    async function onPreviewClick() {
        setShowPreviewModal(true);
        const response = await loadDoc(documentGenerationType.Preview);
        const url = URL.createObjectURL(response.blob);
        setPdfFileName(response.fileName);
        setPdfUrl(url);
    }

    async function onGenerateClick() {
        const response = await loadDoc(documentGenerationType.Generate);
        const url = URL.createObjectURL(response.blob);
        navigate('/GenerateDocumentPage', { state: { pdfURL: url, fileName: response.fileName } });
    }

    document.addEventListener('mousedown', closeOpenMenus);

    return (
        <div>
            <div className="title-bar">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
                            <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-current">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-future">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="container-fluid pt-3 pt-md-4 pb-1 color-bg-bodybg z-2"
                id="plan-list-header"
            >
                <div className="container-xl">
                    <div className='row d-flex align-items-md-center mb-2 mb-lg-4'>
                        <div className='col-12 col-md-6 order-2 order-md-1'>
                            <h1 className='type-20 color-grey-d1 mt-4 mt-md-0 hide-sticky'>
                                {getConfigValue(appState.config?.BatchPrint?.Page?.SelectTarget, appState)?.Value}
                            </h1>
                            <div className="row justify-content-end show-sticky-only">
                                <div className="row justify-content-start">
                                    <div className="col-6 col-md-auto type-14 text-md-start"><div className="color-grey-d1">{getConfigValue(appState.config?.PlanList?.ProductLabel, appState)?.Value}</div>{appState.currentProduct.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 order-1 order-md-2'>
                            <div className='row justify-content-end'>
                                <div className='col-6 col-md-auto type-14 text-md-end'>
                                </div>
                                <div className='col-6 col-md-auto type-14 text-md-end'>
                                    <div className='color-grey-d1'>
                                        {getConfigValue(appState.config?.PlanList?.TerritoryLabel, appState)?.Value}
                                    </div>
                                    {appState.territory?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-8 d-none" id="plan-list-header-spacer"></div>


            <div className="container-xl">
                <div className="row d-flex mb-5">
                    {/* This section will be replaced with the config data to pull in the brands */}
                    <div className="col-12 col-md-4">
                        <ProductsModalButton
                            setShowProductsModal={setShowProductsModal}
                            productImageSource={productImageSource}
                            productName={currentProduct?.productName}
                        ></ProductsModalButton>
                    </div>

                    <div className='col-12 col-md-4 mt-4 mt-md-0'>
                        <CategoryDropdown
                            pageCategory={pageCategory}
                            onDetailsClick={onDetailsClick}
                            isLoading={isLoading}
                        />
                    </div>
                    {hcpsBatchResponses &&
                        <div className="col-12 col-md-4 mt-4 mt-md-0">
                            <PrintOptionDropdown
                                isConfigMenuVisible={isConfigMenuVisible}
                                setIsConfigMenuVisible={setIsConfigMenuVisible}
                                configMenuContentRef={configMenuContentRef}
                                configMenuRef={configMenuRef}
                                configHCPisChecked={configTargetNameisChecked}
                                setConfigHCPisChecked={setConfigTargetNameisChecked}
                                configEHRisChecked={configEHRisChecked}
                                setConfigEHRisChecked={setConfigEHRisChecked}
                                isLoading={isLoading}
                                selectedColumnState={selectedColumnState}
                                setSelectedColumnState={setSelectedColumnState}
                                isDisabled={false}
                                printOptionsEhrBackPageEnabled={printOptionsEhrBackPageEnabled}
                                printOptionsCoverPageEnabled={printOptionsCoverPageEnabled}
                                setConfigCoverisChecked={setConfigCoverisChecked}
                                configCoverisChecked={configCoverisChecked}
                                printConfigColumnHeaders={prescriberData.headers}
                                savingsCardIsChecked={savingsCardIsChecked}
                                setSavingsCardIschecked={setSavingsCardIsChecked}
                                savingsCardIsEnabled={savingsCardIsEnabled}
                            />
                        </div>
                    }
                </div>


                {/* <!-- Filters --> */}

                <div className="row mt-5 mb-3 mb-lg-4 align-items-center">
                    <div className="col-12 col-md-5">
                        <div className="circle-singleline text-bg-info rounded-circle type-18 me-2 text-white">{selectedBatch.length}</div>{getConfigValue(appState.config?.BatchPrint?.Page?.TargetBundle, appState)?.Value}
                        <button className="btn-ghost-primary bg-transparent"
                            data-bs-toggle="modal"
                            data-bs-target="#targets_selected"
                            onClick={() => setShowTargetModal(true)}
                            disabled={selectedBatch.length === 0}
                            type="button">
                            {getConfigValue(appState.config?.BatchPrint?.Page?.Review, appState)?.Value}
                        </button>
                    </div>
                    <div className="col-12 col-md-7">
                        <h3 className="type-24 color-grey-d3 d-md-none my-4">{getConfigValue(appState.config?.BatchPrint?.Page?.Filters, appState)?.Value}</h3>
                        <div className="d-flex flex-column flex-md-row justify-content-md-end">
                            {groupPracticeEnabled &&
                                <>
                                    < div className="form-check mb-3 mb-md-0 me-md-5">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="list-filter-hcps"
                                            checked={hcpCheckbox}
                                            onChange={(event) => handleHcpCheckBoxClick(event)}>
                                        </input>
                                        <label className="form-check-label type-14" htmlFor="list-filter-hcps">{getConfigValue(appState.config?.BatchPrint?.Page?.HcpCheckBox, appState)?.Value}</label>
                                    </div>

                                    <div className="form-check mb-3 mb-md-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="list-filter-groups"
                                            checked={groupPracticeCheckbox}
                                            onChange={(event) => handleGroupPracticeCheckBoxClick(event)}>
                                        </input>
                                        <label className="form-check-label type-14" htmlFor="list-filter-groups">{getConfigValue(appState.config?.BatchPrint?.Page?.GroupPracticeCheckbox, appState)?.Value}</label>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <Select
                            unstyled
                            isMulti
                            type="search"
                            classNames={{
                                control: () => 'form-control color-grey-d1',
                                menuList: () => 'card',
                                option: (state) =>
                                    state.isFocused ? 'bg-color-precision-grey text-white p-2' : 'p-2',
                            }}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 2 })
                            }}
                            placeholder={getConfigValue(appState.config?.BatchPrint?.Page?.ZipPlaceholder, appState)?.Value}
                            onChange={handleZipCode}
                            options={zipCodes}
                            value={selectedZipCodes}
                        >
                        </Select>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control icon-search"
                                placeholder={getConfigValue(appState.config?.BatchPrint?.Page?.SearchPlaceholder, appState)?.Value}
                                value={searchInput}
                                onChange={handleChange}>
                            </input>
                        </div>
                    </div>
                </div>
            </div>
            {
                hcpsBatchResponses && !feedbackMessage &&
                <BatchPrintList
                    headers={hcpsBatchResponses.headers}
                    batchRows={filteredBatchRows}
                    selectedBatch={selectedBatch}
                    handleAddClick={handleAddClick}
                    handleRemoveClick={handleRemoveClick}
                    onOptionsClick={onOptionsClick}
                    showBackButton={false}
                >
                </BatchPrintList>
            }
            {
                feedbackMessage ? (
                    <div className="container-xl mt-5 mb-5 mb-lg-7">
                        <div className='row pd-plan-table-row pd-plan-table-card align-items-center'>
                            <div className='color-grey-d1 py-4'>{feedbackMessage}</div>
                        </div>
                    </div>
                ) : null
            }

            <div className="button-bottom-container-sticky">
                <div className="container-xl">
                    <div className="row justify-content-center justify-content-lg-between py-3">
                        <div className="col-10 col-sm-8 col-md-6 col-xl-5">
                            <div className="d-flex flex-column flex-lg-row">
                                <button
                                    onClick={onPreviewClick}
                                    disabled={!isValid || isLoading}
                                    className={`btn btn-outline-primary btn-lg flex-fill w-100 ${isValid || isLoading ? "" : "disabled"}`}
                                >
                                    {getConfigValue(appState.config?.PlanList?.Preview.Label, appState)?.Value || null}
                                </button>
                                <div className="flex-fill w-100 ms-lg-3 pe-3"></div>
                            </div>
                            <hr className="my-5 d-lg-none" />
                        </div>
                        <div className="w-100 d-lg-none"></div>
                        <div className="col-10 col-sm-8 col-md-6 col-xl-5 justify-content-end">
                            <div className="d-flex flex-column flex-lg-row-reverse">
                                <button
                                    className={`btn btn-success btn-lg mb-3 mb-lg-0 ms-lg-3 flex-fill w-100 ${isValid || isLoading ? "" : "disabled"} ${isLoading ? "btn-working" : ""}`}
                                    type="button"
                                    disabled={!isValid || isLoading}
                                    onClick={onGenerateClick}
                                >
                                    {' '}{getConfigValue(appState.config?.PlanList?.Generate?.Label, appState)?.Value || null}
                                </button>
                                <button
                                    type="button"
                                    onClick={handleBackButton}
                                    className="btn btn-secondary btn-lg flex-fill w-100">
                                    {getConfigValue(appState.config?.Common?.Back, appState)?.Value || null}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastAlert show={showToast} setShow={setShowToast} title={toastTitle} message={toastMessage} autohide={toastAutohide} />

            <MessageDetailsModal
                show={showMessageDetailsModal}
                setShow={setShowMessageDetailsModal}
                categories={batchPrintCategories}
                onDetailsCategoryClick={onDetailsCategoryClick}
            />

            <ProductsModal
                show={showProductsModal}
                setShow={setShowProductsModal}
                onProductClick={onProductClick}
            />

            <PreviewModal
                show={showPreviewModal}
                setShow={setShowPreviewModal}
                onGenerateClick={onGenerateClick}
                isValid={isValid}
                isLoading={isLoading}
                pdfUrl={pdfUrl}
                showGenerateBack={true}
                showPrintDownload={false}
                pdfFileName={pdfFileName}
                supportsProfessionalPrinting={true}
            />

            {
                hcpsBatchResponses &&
                <TargetModal
                    show={showTargetModal}
                    setShow={setShowTargetModal}
                    selectedBatch={selectedBatch}
                    headers={hcpsBatchResponses.headers}
                    handleAddClick={handleAddClick}
                    handleRemoveClick={handleRemoveClick}
                    onOptionsClick={onOptionsClick}
                />
            }
        </div >

    );
}

export default SelectTargetBatchPage


