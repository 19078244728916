import { Link } from 'react-router-dom';
import { React, useState, useEffect, useContext } from 'react';
import { Table, Header, HeaderRow, HeaderCell, Body, Row, Cell } from '@table-library/react-table-library/table';
import { useSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
import { AppContext } from "../util/AppContext";
import { getConfigValue } from '../util/getConfigValue';
import { getPrescribersByTerritory, getPrescriberById } from '../API/prescriber'
import { getGroupPracticesByTerritory, getGroupPracticeById } from '../API/groupPractice';
import { filterByValue } from '../util/filterByValue'
import { getSanitizedHtml } from "../util/getSanitizedHtml";
import { useErrorBoundary } from "react-error-boundary";
import { Pagination } from '../components/Pagination';
import { usePagination } from '@table-library/react-table-library/pagination';


//TODO move out inline style into its own class
const setActiveIconColor = {
    color: "white"
}

//TODO Move out inline style into its own class
const THEME_5_COLUMNS = {
    Table: `
        --data-table-library_grid-template-columns: 8% repeat(4, minmax(0, 1fr));
    `,
    Row: `
        &.row-select-selected, 
        &.row-select-single-selected {
        font-weight: normal;
        }
  `
};

const THEME_4_COLUMNS = {
    Table: `
        --data-table-library_grid-template-columns: 8% repeat(3, minmax(0, 1fr));
    `,
    Row: `
        &.row-select-selected, 
        &.row-select-single-selected {
        font-weight: normal;
        }
  `
};

const HCPPage = () => {
    const [appState, setAppState] = useContext(AppContext);

    const [prescribers, setPrescribers] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [data, setData] = useState({ nodes: [] });
    const [searchInput, setSearchInput] = useState("");
    const [showResults, setShowResults] = useState(true);
    const [activeId, setActiveId] = useState(null);
    const { showBoundary } = useErrorBoundary();
    const [numberOfPages, setNumberOfPages] = useState(0);

    const [activeTab, setActiveTab] = useState('1'); // tabs: 1, 2, 3
    const prescriberTab = "1";
    const groupPracticeTab = "2";
    const groupPracticeEnabled = getConfigValue(appState.config.GroupPractice?.Enabled, appState)?.Value === "true";


    useEffect(() => {
        setSearchInput("");
    }, [activeTab])

    useEffect(() => {
        if (appState.prescriber) {
            setActiveId(appState.prescriber.id);
        }
    }, [appState.prescriber])

    useEffect(() => {
        if (!appState?.market?.id || !appState?.territory?.id) {
            return;
        }
        const key = appState.market.id + "|" + appState.territory.id + "|" + activeTab;

        const getPrescribers = async () => {
            const results = await getPrescribersByTerritory(appState.market.id, appState.territory.id);
            setPrescribers(results.rows);
            setHeaders(results.headers);
            sessionStorage.setItem(key, JSON.stringify(results));
        };

        const getGroupPractices = async () => {
            const results = await getGroupPracticesByTerritory(appState.market.id, appState.territory.id);
            setPrescribers(results.rows);
            setHeaders(results.headers);
            sessionStorage.setItem(key, JSON.stringify(results));
        };

        const cachedPrescribers = JSON.parse(sessionStorage.getItem(key));
        if (cachedPrescribers) {
            setPrescribers(cachedPrescribers.rows);
            setHeaders(cachedPrescribers.headers);
        } else if (activeTab === prescriberTab) {
            getPrescribers().catch(error => { showBoundary(error) });
        } else {
            getGroupPractices().catch(error => { showBoundary(error) });
        }

    }, [appState.market, appState.territory, activeTab]);

    const pagination = usePagination(
        data,
        {
            state: {
                page: 0,
                size: appState.config?.Target?.RecordsPerPage?.Value
            },
        },
    )

    useEffect(() => {
        let length = prescribers.length;
        setShowResults(prescribers.length > 0);
        setData({ nodes: prescribers });
        if (searchInput.length > 0) {
            const results = filterByValue(prescribers, searchInput.trim(), [], true);
            length = results.length;
            if (results.length) {
                setShowResults(true);
            } else {
                setShowResults(false);
            }
            pagination.fns.onSetPage(0);
            setData({ nodes: results })
        }
        const pageSize = parseInt(appState.config?.Target?.RecordsPerPage?.Value, 10);
        var pages = Math.floor(length / pageSize);
        pages = length % pageSize > 0 ? pages + 1 : pages;
        setNumberOfPages(pages);
    }, [searchInput, prescribers, activeTab]);


    function handleChange(event) {
        setSearchInput(event.target.value)
    }

    //build sort functions object
    const sortFunctions = {};
    for (const header of headers) {
        sortFunctions[header.key] = (array) => array.sort((a, b) => a.columns[header.key]?.localeCompare(b.columns[header.key]));
    }
    let defaultSortKey = "Name";
    if (headers && headers.length > 0) {
        defaultSortKey = headers[0].key;
    }

    /* SORT */
    const sort = useSort(
        data,
        {
            state:
            {
                sortKey: defaultSortKey,
                reverse: false,
            }
        },
        {
            sortFns: sortFunctions
        }
    );

    /* THEME */
    let themeObject = {};

    if (headers.length === 3) {
        themeObject = THEME_4_COLUMNS;
    } else if (headers.length === 4) {
        themeObject = THEME_5_COLUMNS;
    }
    const themeColumns = useTheme(themeObject);


    const getIcon = (sortKey) => {
        if (sort.state.sortKey === sortKey && sort.state.reverse) {
            return <span className='fa-solid fa-arrow-up-short-wide' style={setActiveIconColor}></span>;
        }

        if (sort.state.sortKey === sortKey && !sort.state.reverse) {
            return <span className="fa-solid fa-arrow-down-short-wide" style={setActiveIconColor}></span>;
        }

        return <span className='fa-solid fa-sort' />;
    };

    const handleActive = (id) => {
        // get prescriber's products
        if (activeTab === prescriberTab) {
            getPrescriberById(id, appState?.market?.id, appState?.territory?.id)
                .then(data => {
                    const prescriber = data;
                    const products = prescriber?.products.map(product => product) || null;
                    setAppState({
                        ...appState,
                        prescriber: prescriber,
                        prescriberType: "Prescriber",
                        products: products,
                    });
                }).catch(error => { showBoundary(error) });
            setActiveId(id);
        } else if (activeTab === groupPracticeTab) {
            //get Group practice information
            getGroupPracticeById(id, appState?.market?.id, appState?.territory?.id)
                .then(data => {
                    const prescriber = data;
                    const products = prescriber?.products.map(product => product) || null;
                    setAppState({
                        ...appState,
                        prescriber: prescriber,
                        prescriberType: "Group Practice",
                        products: products,
                    });
                }).catch(error => { showBoundary(error) });
            setActiveId(id);
        }
    };

    function checkIdMatch(id) {
        return id === activeId;
    }


    return (
        <div>
            <div className="title-bar">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
                            <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-current">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-future">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-future">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xl mt-5">
                <div className="row justify-content-center mb-6">
                    <div className="col-12 col-md-10">
                        <h1 className="type-20 color-grey-d1 mb-5">{getConfigValue(appState.config?.Target?.SelectTarget, appState)?.Value}</h1>
                        <div className="card pd-card">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <ul className="nav tabs tabs-stretched mt-3" role="tablist">
                                            <li className="nav-item" role="none" onClick={() => setActiveTab(prescriberTab)}>
                                                <button className={`nav-link ${activeTab === prescriberTab ? 'active' : ''}`} id="tab-group" data-bs-toggle="tab" data-bs-target="#tab-hcp-pane" type="button" role="tab" aria-controls="tab-hcp-pane" aria-selected="true"><div className="fa-solid fa-user-doctor tab-icon"></div> <span className="d-none d-md-inline">{getConfigValue(appState.config?.Target?.SelectHCP?.Title, appState)?.Value}</span></button>
                                            </li>
                                            {groupPracticeEnabled &&
                                                <li className="nav-item" role="none" onClick={(() => setActiveTab(groupPracticeTab))}>
                                                    <button className={`nav-link ${activeTab === groupPracticeTab ? 'active' : ''}`} id="tab-group" data-bs-toggle="tab" data-bs-target="#tab-group-pane" type="button" role="tab" aria-controls="tab-group-pane" aria-selected="true"><div className="fa-solid fa-hospital tab-icon"></div> <span className="d-none d-md-inline">{getConfigValue(appState.config?.Target?.SelectGroupPractice?.Title, appState)?.Value} </span></button>
                                                </li>
                                            }
                                        </ul>
                                        {/* SEARCH FIELD */}
                                        <div className="row my-4">
                                            <div className="col-12">
                                                <div className="input-group">
                                                    <input
                                                        className="form-control icon-search"
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchInput}
                                                        onChange={handleChange}>
                                                    </input>
                                                </div>
                                            </div>
                                        </div>

                                        {showResults && headers.length > 0 &&
                                            <Table className='table pd-table pod-table-hcp' data={data} sort={sort} theme={themeColumns} layout={{ custom: true, isDiv: true }} pagination={pagination}>
                                                {(tableList) => (
                                                    <>
                                                        <Header>
                                                            <HeaderRow className='table-dark d-none d-md-contents'>
                                                                <HeaderCell className='px-2 py-2' scope='col'></HeaderCell>
                                                                {headers.map((header) => <HeaderCell key={header.key} scope='col'>
                                                                    {header.title}<button
                                                                        className='btn-ghost btn-table'
                                                                        children={getIcon(header.key)}
                                                                        onClick={() =>
                                                                            sort.fns.onToggleSort({
                                                                                sortKey: header.key,
                                                                            })
                                                                        }
                                                                    >
                                                                    </button>
                                                                </HeaderCell>)}
                                                            </HeaderRow>
                                                        </Header>
                                                        <Body>
                                                            {tableList.map((item) => (
                                                                <Row
                                                                    key={item.data.id}
                                                                    item={item}
                                                                    onClick={() => { handleActive(item.data.id) }}
                                                                    className={activeId === item.data.id ? 'table-active' : {}}
                                                                >
                                                                    <Cell><div className='input-container'>
                                                                    <input className="form-check-input" type="radio" name="selectHCP" id={"selectHCP" + item.data.id} checked={checkIdMatch(item.data.id)} onChange={() => { handleActive(item.data.id) }} ></input></div></Cell>
                                                                    {Object.keys(item.columns).map((key) => <Cell key={key}>
                                                                        {<span className="d-block d-md-none color-grey-d1">{item.columns[key] ? headers.find(h => h.key === key)?.title : ""}</span>}
                                                                        <span className="form-check-label" htmlFor={"selectHCP" + item.data.id} dangerouslySetInnerHTML={getSanitizedHtml(item.columns[key])}></span></Cell>)}

                                                                </Row>
                                                            ))}
                                                        </Body>
                                                    </>
                                                )}
                                            </Table>
                                        }

                                        {!showResults &&
                                            <div className="color-grey-d1 pb-4">{getConfigValue(appState.config?.Common?.NoResultsFound, appState)?.Value}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {numberOfPages > 1 && pagination && <Pagination numberOfPages={pagination.state.getTotalPages(data.nodes)} currentPage={pagination.state.page + 1} setCurrentPage={(index, e) => {
                                e.preventDefault();
                                pagination.fns.onSetPage(index - 1);
                            }} />}
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mb-7">
                    <div className="col-10 col-sm-8 col-md-6 offset-md-4 col-lg-5 offset-lg-5  col-xxl-4 offset-xxl-6 justify-content-end">
                        <div className="d-flex flex-column flex-md-row-reverse">
                            {!activeId && (
                                <>
                                    <Link
                                        to='#'
                                        className='btn btn-primary disabled btn-lg pe-7 mb-3 mb-md-0 ms-md-3 flex-fill w-100'>
                                        {getConfigValue(appState.config?.Common?.Continue, appState)?.Value}
                                    </Link>
                                </>
                            )}
                            {(activeId && showResults) && (
                                <>
                                    <Link
                                        to='/MessageSelectionPage'
                                        className='btn btn-primary btn-lg pe-7 mb-3 mb-md-0 ms-md-3 flex-fill w-100'>
                                        {getConfigValue(appState.config?.Common?.Continue, appState)?.Value}
                                    </Link>
                                </>
                            )}
                            <Link to="/TerritoryPage" className="btn btn-secondary btn-lg pe-7 flex-fill w-100">{getConfigValue(appState.config?.Common?.Back, appState)?.Value}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HCPPage;