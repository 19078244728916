export async function getBatchPrintMessages(territoryId, productId) {
    if (!territoryId || !productId) {
        throw new Error('No territoryId or productId provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}BatchPrint/Messages/productId?territoryId=${encodeURIComponent(territoryId)}&productId=${encodeURIComponent(productId)}`;
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getBatchPrintZipCodes(territoryId, messageTypeId, productId) {
    if (!territoryId || !messageTypeId || !productId) {
        throw new Error('No territoryId or messageTypeId or productId provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}BatchPrint/ZipCodes?territoryId=${encodeURIComponent(territoryId)}&messageTypeId=${encodeURIComponent(messageTypeId)}&productId=${encodeURIComponent(productId)}`
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getBatchPrintHCPs(messageTypeId, productId, territoryId, zipCodes) {
    if (!territoryId || !messageTypeId || !productId) {
        throw new Error('No territoryId or messageTypeId or productId provided.');
    }
    const url = `${process.env.REACT_APP_API_URL}BatchPrint/HCPs`;

    const data = {
        messageTypeId: messageTypeId,
        productId: productId,
        territoryId: territoryId,
        zipCodes: zipCodes
    };

    const parameters = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }

    const response = await fetch(url, parameters);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}

export async function getBatchPrintPrescriber(level, messageCode, id, productId, messageTypeId, territoryId) {
    if (!level || !messageCode || !id || !productId || !messageTypeId || !territoryId) {
        throw new Error('No level or messageCode or id or productId or messageTypeId or territoryId was provided.');
    }

    const url = `${process.env.REACT_APP_API_URL}BatchPrint/Prescriber/Message/${encodeURIComponent(level)}/${encodeURIComponent(messageCode)}/${encodeURIComponent(id)}/${encodeURIComponent(productId)}/${encodeURIComponent(messageTypeId)}/${encodeURIComponent(territoryId)}`
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 200) {
        return await response.json();
    } else {
        throw response.statusText;
    }
}